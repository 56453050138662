module services {
    export module master {
        export class productService implements interfaces.master.IProductService {
            static $inject = ["$resource", "ENV","$http","$timeout", "generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig,private $http: ng.IHttpService,private $timeout: ng.ITimeoutService,public generalService: interfaces.applicationcore.IGeneralService) {
            }

            getProduct(): ng.resource.IResourceClass<interfaces.master.IProductMaster> {

                return this.$resource<interfaces.master.IProductMaster>(this.ENV.DSP_URL + "Product/GetProduct", {
                    Id: '@Id'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                       
                    });
            }

            getSKU(): ng.resource.IResourceClass<interfaces.master.IProductSKU> {

                return this.$resource<interfaces.master.IProductSKU>(this.ENV.DSP_URL + "Product/GetSKU", {
                    Id: '@Id'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false
                        }
                    });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Product/Save");
            }

            getList(): ng.resource.IResourceClass<interfaces.master.IProductMasterDisplay> {
                return this.$resource<interfaces.master.IProductMasterDisplay>(this.ENV.DSP_URL + "Product/GetList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1,
                            }
                        }
                    });
            }

            getProductListExcel(params: any): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'Product/GetProductsExcel?';

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                searchObject = params.connectSearch;

                url += 'connectSearch=' + btoa(JSON.stringify(searchObject));

                if (params.items)
                {
                    _.forEach(params.items, (item: string) => {
                        url += "&items=" + item;
                    });
                }
                    

                if (params.entityCode)
                    url += "&entityCode=" + params.entityCode;

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'Products.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            getBySKUId(): ng.resource.IResourceClass<interfaces.master.IProduct> {

                return this.$resource<interfaces.master.IProduct>(this.ENV.DSP_URL + "Product/GetBySKUId", {
                    skuId: '@skuId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false
                        }
                    });
            }


            getProductImage(imageId: number): ng.resource.IResourceClass<interfaces.master.IProductImage> {

                return this.$resource<interfaces.master.IProductImage>(this.ENV.DSP_URL + "Product/GetProductImage", {
                    imageId: imageId
                }, {
                        query: {
                            method: 'GET',
                            isArray: false
                        }
                    });
            }


            getDropdownList(ownerEntityId: number, searchText: string): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Product/GetDropdownList", {
                    ownerEntityId: ownerEntityId,
                    searchText: searchText,
                });
            }

            getProductCostGroupList(ownerEntityId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Product/GetProductCostGroupList", {
                    ownerEntityId: ownerEntityId
                });
            }
            
        }
    }
    angular.module("app").service("productService", services.master.productService);
}